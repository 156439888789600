import {HttpErrorResponse, HttpResponse, HttpStatusCode} from '@angular/common/http';
import {DestroyRef, inject, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Action, State, StateContext} from '@ngxs/store';
import {catchError, EMPTY, Observable, of, tap, throwError} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {CookieConstants} from '@shared/core/constants/cookie.const';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {isEmpty} from 'lodash';
import {CookieService} from 'ngx-cookie-service';
import {Language, RequestRestService} from 'projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {
  BankAccountViewDto,
  CodeDto,
  CommunicationLanguageType,
  EmailDto,
  EmergencyContactDto,
  MilitaryRestService,
  PersonCivilDataDto,
  PersonMilitaryCurrentDataDto,
  PersonMilitaryHistoryDataDto,
  PersonMilitaryServiceDaysExtendedDto,
  PersonRestService,
  PhoneUpdateDto,
  PreferencesDto,
  PreferencesRestService,
  SwissPassDto,
  SwissPassRestService
} from 'projects/person-data/src/app/core/api/generated/msa-person-data';
import {ChangeLanguage, OnAuthenticated} from '../actions/app.state.action';
import {
  DeleteSwissPass,
  FetchCurrentMilitaryData,
  FetchMilitaryHistoryData,
  FetchPendingRequests,
  FetchPersonData,
  FetchPersonPreferences,
  FetchServiceDaysData,
  FetchSwissPass,
  ResetStatusCode,
  UpdateAndFetchPersonPreferences,
  UpdateBankAccountData,
  UpdateEmails,
  UpdateEmergencyContacts,
  UpdateLanguageSkills,
  UpdateOccupation,
  UpdatePhones,
  UpdateSwissPass
} from '../actions/person-data.state.actions';
import {PersonDataStateModel} from '../models/person-data.state.model';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';

@State<PersonDataStateModel>({
  name: 'personData',
  defaults: {
    errorMessage: null,
    militaryData: null,
    pendingRequests: null,
    personData: null,
    preferences: [],
    statusCode: null,
    militaryHistoryData: [],
    swissPass: null,
    serviceDaysData: null
  }
})
@Injectable()
export class PersonDataState {
  private document = inject(DOCUMENT);

  constructor(
    private personRestService: PersonRestService,
    private cookieService: CookieService,
    private destroyRef: DestroyRef,
    private requestRestService: RequestRestService,
    private militaryRestService: MilitaryRestService,
    private preferenceRestService: PreferencesRestService,
    private swissPassRestService: SwissPassRestService,
    private router: Router,
    private errorMessageHandler: ErrorMessageHandler,
    private snackbarService: SnackbarService
  ) {}

  public static mapCommunicationLanguageToAppLanguage(userLanguage: CommunicationLanguageType | undefined): Language {
    switch (userLanguage) {
      case CommunicationLanguageType.Fra:
        return Language.Fr;
      case CommunicationLanguageType.Ita:
        return Language.It;
      case CommunicationLanguageType.Ger:
      default:
        return Language.De;
    }
  }

  private static updatePersonData(ctx: StateContext<PersonDataStateModel>, personData: PersonCivilDataDto): void {
    ctx.patchState({personData});
  }

  private static setStatusCode(ctx: StateContext<PersonDataStateModel>, statusCode: number) {
    ctx.patchState({statusCode});
  }

  private static setPendingRequests(ctx: StateContext<PersonDataStateModel>, pendingRequests: number) {
    if (!Number.isNaN(pendingRequests)) {
      ctx.patchState({pendingRequests});
    }
  }

  @Action(FetchPersonData)
  @Action(OnAuthenticated)
  getPersonData(ctx: StateContext<PersonDataStateModel>): Observable<HttpResponse<PersonCivilDataDto>> {
    const {personData} = ctx.getState();
    if (personData) {
      return EMPTY;
    }
    return this.personRestService.getPersonCivilData('response').pipe(
      tap((response: HttpResponse<PersonCivilDataDto>) => PersonDataState.updatePersonData(ctx, response.body!)),
      tap(response => PersonDataState.setStatusCode(ctx, response.status)),
      tap(response => {
        if (response.status === HttpStatusCode.NoContent) {
          this.router.navigate(['user-not-loaded']);
        }
      }),
      tap(() => this.setInitialLanguage(ctx)),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
        }
        return this.handleError(error, ctx);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(ResetStatusCode)
  resetStatusCode(ctx: StateContext<PersonDataStateModel>) {
    PersonDataState.setStatusCode(ctx, 0);
  }

  @Action(FetchSwissPass)
  getSwissPass(ctx: StateContext<PersonDataStateModel>): Observable<SwissPassDto> {
    return this.swissPassRestService.getSwissPass().pipe(
      tap(result => {
        ctx.patchState({swissPass: result});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
        }
        return this.handleError(error, ctx);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateSwissPass)
  updateSwissPass(ctx: StateContext<PersonDataStateModel>, action: UpdateSwissPass): Observable<SwissPassDto> {
    return this.swissPassRestService.updateSwissPass(action.payload).pipe(
      tap((swissPass: SwissPassDto) => {
        ctx.patchState({swissPass: swissPass});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
        }
        return throwError(() => error);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(DeleteSwissPass)
  deleteSwissPass(ctx: StateContext<PersonDataStateModel>): Observable<SwissPassDto> {
    return this.swissPassRestService.deleteSwissPass().pipe(
      tap(() => {
        ctx.patchState({swissPass: null});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
          this.handleError(error, ctx);
        }
        return throwError(() => error);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchMilitaryHistoryData)
  getMilitaryHistoryData(ctx: StateContext<PersonDataStateModel>): Observable<Array<PersonMilitaryHistoryDataDto>> {
    return this.militaryRestService.getPersonMilitaryDataHistory().pipe(
      tap(result => {
        ctx.patchState({militaryHistoryData: result});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
        }
        return this.handleError(error, ctx);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchCurrentMilitaryData)
  @Action(OnAuthenticated)
  getPersonMilitaryData(ctx: StateContext<PersonDataStateModel>): Observable<PersonMilitaryCurrentDataDto> {
    return this.militaryRestService.getPersonMilitaryData().pipe(
      tap(result => {
        ctx.patchState({militaryData: result});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
        }
        return this.handleError(error, ctx);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchServiceDaysData)
  getServiceDaysData(ctx: StateContext<PersonDataStateModel>): Observable<PersonMilitaryServiceDaysExtendedDto> {
    return this.militaryRestService.getPersonMilitaryServiceDays().pipe(
      tap(result => {
        ctx.patchState({serviceDaysData: result});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
        }
        return this.handleError(error, ctx);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchPendingRequests)
  getPendingRequests(ctx: StateContext<PersonDataStateModel>): Observable<number> {
    const {pendingRequests} = ctx.getState();
    if (isDefined(pendingRequests)) {
      return of(pendingRequests);
    }

    //TODO: Should be the general request REST service
    return this.requestRestService.count().pipe(
      tap((response: number) => {
        PersonDataState.setPendingRequests(ctx, response);
      }),
      catchError((error: unknown) => this.handleError(error, ctx)),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateBankAccountData)
  updateBankAccount(
    ctx: StateContext<PersonDataStateModel>,
    action: UpdateBankAccountData
  ): Observable<BankAccountViewDto | unknown> {
    return this.personRestService.updatePersonBankAccount(action.payload).pipe(
      tap((bankAccountViewDto: BankAccountViewDto) => {
        ctx.patchState({
          personData: {
            ...ctx.getState().personData,
            bankAccount: bankAccountViewDto
          }
        });
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
          this.handleError(error, ctx);
        }
        return throwError(() => error);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateEmergencyContacts)
  updateEmergencyContacts(
    ctx: StateContext<PersonDataStateModel>,
    action: UpdateEmergencyContacts
  ): Observable<Array<EmergencyContactDto> | unknown> {
    return this.personRestService.updateEmergencyContacts(action.payload).pipe(
      tap((emergencyContacts: Array<EmergencyContactDto>) => {
        ctx.patchState({
          personData: {
            ...ctx.getState().personData,
            emergencyContacts: emergencyContacts
          }
        });
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
          this.handleError(error, ctx);
        }
        return throwError(() => error);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateEmails)
  updateEmails(ctx: StateContext<PersonDataStateModel>, action: UpdateEmails): Observable<Array<EmailDto> | unknown> {
    return this.personRestService.updatePersonEmails(action.payload).pipe(
      tap((emailsDto: Array<EmailDto>) => {
        ctx.patchState({
          personData: {
            ...ctx.getState().personData,
            emails: emailsDto
          }
        });
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
          this.handleError(error, ctx);
        }
        return throwError(() => error);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdatePhones)
  updatePhones(
    ctx: StateContext<PersonDataStateModel>,
    action: UpdatePhones
  ): Observable<Array<PhoneUpdateDto> | unknown> {
    return this.personRestService.updatePersonPhone(action.payload).pipe(
      tap((phoneDto: Array<PhoneUpdateDto>) => {
        ctx.patchState({
          personData: {
            ...ctx.getState().personData,
            phones: phoneDto
          }
        });
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
          this.handleError(error, ctx);
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateOccupation)
  updateOccupation(ctx: StateContext<PersonDataStateModel>, action: UpdateOccupation): Observable<CodeDto> {
    return this.personRestService.updateOccupation(action.payload).pipe(
      tap((occupationCode: CodeDto) => {
        ctx.patchState({
          personData: {
            ...ctx.getState().personData,
            occupationCode: occupationCode
          }
        });
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          PersonDataState.setStatusCode(ctx, error.status);
          this.handleError(error, ctx);
        }
        return throwError(() => error);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchPersonPreferences)
  getPersonPreferences(ctx: StateContext<PersonDataStateModel>): Observable<PreferencesDto> {
    return this.preferenceRestService.getPreferences().pipe(
      tap((preferences: PreferencesDto) => {
        ctx.patchState(preferences);
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          this.handleError(error, ctx);
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateLanguageSkills)
  updateLanguageSkills(ctx: StateContext<PersonDataStateModel>, action: UpdateLanguageSkills) {
    return this.personRestService.updateLanguageSkills(action.payload).pipe(
      tap(langugageSkills => {
        ctx.patchState({
          personData: {
            ...ctx.getState().personData,
            languageSkills: langugageSkills
          }
        });
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          this.handleError(error, ctx);
        }
        return throwError(() => error);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateAndFetchPersonPreferences)
  updateAndFetchPersonPreferences(
    ctx: StateContext<PersonDataStateModel>,
    action: UpdateAndFetchPersonPreferences
  ): Observable<PreferencesDto> {
    return this.preferenceRestService.updatePreferences(action.payload).pipe(
      tap((preferences: PreferencesDto) => {
        ctx.patchState(preferences);
      }),
      catchError((error: unknown) => {
        return this.handleError(error, ctx);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private setInitialLanguage(ctx: StateContext<PersonDataStateModel>) {
    const {personData} = ctx.getState();

    const communicationLanguage = PersonDataState.mapCommunicationLanguageToAppLanguage(
      personData?.communicationLanguage
    );
    const userLanguagePreference = this.cookieService.get(CookieConstants.LANGUAGE);

    const initialLanguage = isEmpty(userLanguagePreference) ? communicationLanguage : userLanguagePreference;

    if (initialLanguage) {
      ctx.dispatch(new ChangeLanguage(initialLanguage));
      this.document.documentElement.lang = initialLanguage;
    }
  }

  private handleError(error: unknown, ctx: StateContext<PersonDataStateModel>): Observable<never> {
    const errorMessage = 'i18n.person-data.inline-warning-message';
    ctx.patchState({errorMessage});
    return this.errorMessageHandler.logAndIgnore(error);
  }
}
