/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {createPropertySelectors, Selector} from '@ngxs/store';
import {NavigationMenuData} from '@shared/shared-module/components/navigation-menu/navigation-menu.component';
import {TopBarData} from '@shared/shared-module/models/top-bar-data.model';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {
  BankAccountViewDto,
  BankAccountViewDtoOwner,
  CommunicationLanguageType,
  DescriptionDto,
  EmailType,
  EmergencyContactDto,
  LanguageSkillsDto,
  PersonCivilDataDto,
  PersonCivilDataDtoGenderEnum,
  PersonMilitaryCurrentDataDto,
  PersonMilitaryHistoryDataDto,
  PersonMilitaryServiceDaysExtendedDto,
  PhoneType,
  PreferenceDto,
  PreferenceDtoNameEnum,
  RankAddition,
  SwissPassDto
} from '../../../../../projects/person-data/src/app/core/api/generated/msa-person-data';
import {PersonDataStateModel} from '../models/person-data.state.model';
import {PersonDataState} from '../states/person-data.state';
import {AppStateSelectors} from './app.state.selectors';

export interface CurrentMilitaryData {
  currentDutyDay: number;
  division: DescriptionDto | undefined;
  firstName: string;
  gender: string;
  highAvailability: boolean | undefined;
  lastName: string;
  maxDutyDay: number | undefined;
  militaryFunction: DescriptionDto | undefined;
  rank: DescriptionDto | undefined;
  rankAddition: TranslationString | undefined;
  rankIcon: string | undefined;
  rankShort: DescriptionDto | undefined;
  troop: DescriptionDto | undefined;
  troopShort: DescriptionDto | undefined;
  troopIcon: string | undefined;
}

export interface ServiceDaysData {
  remainingDays: number | undefined;
  completedDays: number | undefined;
  totalDays: number | undefined;
  servedDays: number | undefined;
  totalCompletedDays: number | undefined;
  /*  isSpecialist: boolean | undefined;
                    isActiveMember: boolean | undefined;
                    isLeader: boolean | undefined;*/
}

export type PersonHeaderData = {
  firstName: string;
  lastName: string;
  gender: string;
  communicationLanguage: string;
};

export interface NotificationSettingsData {
  email: {
    address: string;
    enabled: boolean;
  };
  sms: {
    number: string;
    enabled: boolean;
  };
}

export interface CommunicationPhonesByType {
  CELL: {number: string; preferred: boolean};
  CELP: {number: string; preferred: boolean};
  HOME: {number: string};
  BUSN: {number: string};
}

export interface CommunicationEmailsByType {
  BUSN: {address: string; preferred: boolean};
  OTHR: {address: string; preferred: boolean};
  HOME: {address: string; preferred: boolean};
}

export class PersonDataStateSelectors {
  static readonly slices = createPropertySelectors<PersonDataStateModel>(PersonDataState);

  @Selector([PersonDataStateSelectors.slices.personData, AppStateSelectors.slices.language])
  static getPersonalData(personData: PersonCivilDataDto): PersonCivilDataDto {
    return personData;
  }

  @Selector([PersonDataStateSelectors.slices.swissPass])
  static getSwissPass(swissPass: SwissPassDto): SwissPassDto {
    return swissPass;
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getCommunicationPhonesByType(personData: PersonCivilDataDto): CommunicationPhonesByType {
    const phonesByType: CommunicationPhonesByType = {
      CELL: {number: '', preferred: false},
      CELP: {number: '', preferred: false},
      HOME: {number: ''},
      BUSN: {number: ''}
    };
    if (!personData || !personData.phones) {
      return phonesByType;
    }
    personData.phones.forEach(phone => {
      if (phone.type && phone.number) {
        switch (phone.type) {
          case PhoneType.Cell:
            phonesByType.CELL = {number: phone.number, preferred: !!phone.preferred};
            break;
          case PhoneType.Celp:
            phonesByType.CELP = {number: phone.number, preferred: !!phone.preferred};
            break;
          case PhoneType.Home:
            phonesByType.HOME = {number: phone.number};
            break;
          case PhoneType.Busn:
            phonesByType.BUSN = {number: phone.number};
            break;
        }
      }
    });
    return phonesByType;
  }

  @Selector([PersonDataStateSelectors.slices.militaryHistoryData])
  static getMilitaryHistoryData(
    militaryHistoryData: Array<PersonMilitaryHistoryDataDto>
  ): Array<PersonMilitaryHistoryDataDto> | undefined {
    return militaryHistoryData;
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getCommunicationEmailsByType(personData: PersonCivilDataDto): CommunicationEmailsByType {
    const emailsByType: CommunicationEmailsByType = {
      BUSN: {address: '', preferred: false},
      HOME: {address: '', preferred: false},
      OTHR: {address: '', preferred: false}
    };
    if (!personData || !personData.emails) {
      return emailsByType;
    }
    personData.emails.forEach(email => {
      if (email.type && email.address) {
        switch (email.type) {
          case EmailType.Busn:
            emailsByType.BUSN = {address: email.address, preferred: !!email.preferred};
            break;
          case EmailType.Home:
            emailsByType.HOME = {address: email.address, preferred: !!email.preferred};
            break;
          case EmailType.Othr:
            emailsByType.OTHR = {address: email.address, preferred: !!email.preferred};
            break;
        }
      }
    });
    return emailsByType;
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getCommunicationLanguage(personData: PersonCivilDataDto): CommunicationLanguageType | undefined {
    return personData?.communicationLanguage;
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getLanguageSkills(personData: PersonCivilDataDto): Array<LanguageSkillsDto> | undefined {
    return personData?.languageSkills;
  }

  @Selector([PersonDataStateSelectors.slices.preferences])
  static getNotificationByType(preferences: Array<PreferenceDto>): {
    NOTIFICATION_RECEIVE_EMAIL?: boolean;
    NOTIFICATION_RECEIVE_SMS?: boolean;
    NOTIFICATION_RECEIVE_PORTAL?: boolean;
  } {
    return preferences.reduce(
      (accumulator, preference: PreferenceDto) => {
        if (preference.name && preference.value) {
          accumulator[preference.name] = preference.value === 'true';
        }
        return accumulator;
      },
      {} as {[key in PreferenceDtoNameEnum]?: boolean}
    );
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getEmergencyContacts(personData: PersonCivilDataDto): Array<EmergencyContactDto> {
    return personData?.emergencyContacts ?? [];
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getLanguageData(personData: PersonCivilDataDto | null | undefined): LanguageSkillsDto[] {
    return personData?.languageSkills ?? [];
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getOccupation(personData: PersonCivilDataDto): string {
    return personData?.occupationCode?.codeHash ?? '';
  }

  @Selector([PersonDataStateSelectors.slices.preferences, PersonDataStateSelectors.slices.personData])
  static getNotificationSettingsData(
    preferences: PreferenceDto[],
    personData: PersonCivilDataDto
  ): NotificationSettingsData {
    return {
      email: {
        address: personData.emails?.find(email => email.preferred === true)?.address ?? '',
        enabled: preferences.find(preference => preference.name === PreferenceDtoNameEnum.Email)?.value === 'true'
      },
      sms: {
        number: personData.phones?.find(email => email.preferred === true)?.number ?? '',
        enabled: preferences.find(preference => preference.name === PreferenceDtoNameEnum.Sms)?.value === 'true'
      }
    };
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static headerData(personData: PersonCivilDataDto | null): PersonHeaderData | null {
    if (!personData) {
      return null;
    }

    return {
      firstName: personData.firstName ?? '',
      lastName: personData.lastName ?? '',
      gender: this.toGender(personData.gender),
      communicationLanguage: PersonDataState.mapCommunicationLanguageToAppLanguage(personData?.communicationLanguage)
    };
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getTopBarData(personData: PersonCivilDataDto | null): TopBarData | null {
    if (!personData) {
      return null;
    }
    return {
      firstName: personData.firstName ?? '',
      lastName: personData.lastName ?? '',
      communicationLanguage: personData.communicationLanguage ?? ''
    };
  }

  @Selector([PersonDataStateSelectors.slices.personData, PersonDataStateSelectors.slices.militaryData])
  static getCurrentMilitaryData(
    personData: PersonCivilDataDto | null,
    militaryData: PersonMilitaryCurrentDataDto | null
  ): CurrentMilitaryData | null {
    if (!personData) return null;

    return {
      currentDutyDay: militaryData?.serviceDays?.completedDays ?? 0,
      division: militaryData?.division?.descriptionDto,
      firstName: personData?.firstName ?? '',
      gender: this.toGender(personData?.gender) ?? '',
      highAvailability: militaryData?.highAvailabilityUnit,
      lastName: personData?.lastName ?? '',
      maxDutyDay: militaryData?.serviceDays?.totalDays,
      militaryFunction: militaryData?.militaryFunction?.descriptionDto,
      rank: militaryData?.rank?.descriptionDto,
      rankAddition: militaryData?.rankAddition === RankAddition.J ? 'i18n.user.banner.rank-addition.j' : undefined,
      rankShort: militaryData?.rank?.shortDescriptionDto,
      rankIcon: militaryData?.rank?.icon,
      troopShort: militaryData?.troop?.shortDescriptionDto,
      troop: militaryData?.troop?.descriptionDto,
      troopIcon: militaryData?.troop?.icon
    };
  }

  @Selector([PersonDataStateSelectors.slices.serviceDaysData])
  static getServiceDays(serviceData: PersonMilitaryServiceDaysExtendedDto | null): ServiceDaysData | null {
    if (!serviceData) return null;

    return {
      remainingDays: serviceData?.remainingDays,
      completedDays: serviceData?.completedDays,
      totalDays: serviceData?.totalDays,
      servedDays: serviceData?.remainingDays,
      totalCompletedDays: serviceData?.remainingDays
      /*isSpecialist: militaryData?.highAvailabilityUnit,
                              isActiveMember: militaryData?.highAvailabilityUnit,
                              isLeader: militaryData?.highAvailabilityUnit*/
    };
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getNavigationMenuData(personData: PersonCivilDataDto | null): NavigationMenuData | null {
    if (!personData) {
      return null;
    }
    return {
      firstName: personData.firstName ?? '',
      lastName: personData.lastName ?? ''
    };
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getBankAccountData(personData: PersonCivilDataDto | null): BankAccountViewDto | undefined {
    return personData?.bankAccount;
  }

  @Selector([PersonDataStateSelectors.slices.personData])
  static getBankAccountHolderData(personData: PersonCivilDataDto | null): BankAccountViewDtoOwner | undefined {
    return personData?.bankAccount?.owner;
  }

  private static toGender(gender: PersonCivilDataDtoGenderEnum | undefined): string {
    switch (gender) {
      case PersonCivilDataDtoGenderEnum.M:
        return 'male';
      case PersonCivilDataDtoGenderEnum.F:
        return 'female';
      default:
        return 'male';
    }
  }
}
